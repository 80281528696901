import styled from '@emotion/styled';
import { css, keyframes } from '@emotion/core';
import { Button as RebassButton } from 'rebass';

const rotate = keyframes`
from {
  transform: rotate(0deg);
}
to {
  transform: rotate(360deg);
}
`;

export const Button = styled(RebassButton)`
  background-color: ${({ theme }) => theme.colors.secondary};
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  height: 36px;
  line-height: 15px;
  outline: none;
  padding: 8px 15px;

  &:disabled {
    opacity: 0.35;
    cursor: default;
  }

  ${({ loading }) =>
    loading &&
    css`
      color: transparent !important;
      pointer-events: none;
      position: relative;

      &::after {
        content: '';
        animation: ${rotate} 500ms infinite linear;
        border: 2px solid #fff;
        border-radius: 50%;
        border-right-color: transparent !important;
        border-top-color: transparent !important;
        display: block;
        height: 1.2em;
        width: 1.2em;
        position: absolute;
        left: calc(50% - (1.2em / 2));
        top: calc(50% - (1.2em / 2));
        -webkit-transform-origin: center;
        transform-origin: center;
        position: absolute !important;
      }
    `}
`;
