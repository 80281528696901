import React from 'react';
import styled from '@emotion/styled/macro';
import { css } from '@emotion/core';
import { Button } from 'rebass';

const HamburgerBar = styled.span`
  background: #a5acc0;
  border-radius: 2px;
  content: '';
  display: block;
  height: 2px;
  position: relative;
  transition: 0.3s all;
  width: 18px;
  &:not(:last-child) {
    margin-bottom: 3px;
  }
`;

const HamBurgerButton = styled(Button)`
  background-color: transparent;
  border-radius: 0;
  cursor: pointer;
  display: block;
  outline: none;
  padding: 5px;
  position: relative;

  ${({ active }) =>
    active &&
    css`
      ${HamburgerBar} {
        &:first-child {
          transform: rotate(45deg);
          top: 6px;
        }
        &:nth-of-type(2) {
          opacity: 0;
        }
        &:last-child {
          transform: rotate(-45deg);
          top: -4px;
        }
      }
    `}
`;

export const Hamburger = ({ ...props }) => {
  return (
    <HamBurgerButton {...props} aria-label="Hamburger">
      <HamburgerBar />
      <HamburgerBar />
      <HamburgerBar />
    </HamBurgerButton>
  );
};
