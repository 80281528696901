import crypto from 'crypto';
import jsSHA from 'jssha';

export const generatePrivateKey = (data, key = 'OPAYPRV15858807854340.25389262573300486') => {
  return crypto.createHmac('sha512', key).update(JSON.stringify(data)).digest('hex');
};

export const generateCallbackPrivateKey = (data, key = 'OPAYPRV15858807854340.25389262573300486') => {
  const shaObj = new jsSHA('SHA3-512', 'TEXT', { hmacKey: { value: key, format: 'TEXT' } });
  shaObj.update(data);
  const hash = shaObj.getHMAC('HEX');
  return hash;
};
