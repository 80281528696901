import React from 'react';
import LeftArrow from '@icons/left-arrow.svg';
import styled from '@emotion/styled';
import { Box, Flex, Text } from 'rebass';
import { Button as AppButton } from '../Button';

export const Wrapper = styled(Flex)`
  background: #ffffff80;
  border-radius: 6px;
  border: 1px solid rgba(165, 172, 192, 0.3);
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.07);
  flex-direction: column;
  overflow: hidden;

  ${({ theme }) => theme.mq.md`
    flex-direction: row;
  `}
`;

export const Left = styled(Flex)`
  flex-direction: column;
  padding: 2rem;
  width: 100%;

  ${({ theme }) => theme.mq.md`
    min-height: 30rem;
    max-width: 26rem;
  `}
`;

export const Right = styled(Box)`
  background: #423e57;
  width: 100%;
`;

export const Title = styled((props) => (
  <Text variant="inlineHeader" color="header" fontSize={['sm', null, 'md']} mb="7px" {...props} />
))``;

export const Subtitle = styled((props) => <Text variant="smallBody" {...props} />)``;

export const BackButtonWrapper = styled(Flex)`
  align-items: center;
  appearance: none;
  background: none;
  border: none;
  cursor: pointer;
  margin-bottom: 20px;

  svg {
    margin-right: 7px;
  }

  span {
    display: inline-block;
  }
`;

export const Button = styled(AppButton)`
  width: 100%;
`;

export const BackButton = (props) => {
  return (
    <BackButtonWrapper as="button" {...props}>
      <LeftArrow />
      <Text variant="smallBody" color="secondary" as="span">
        Back
      </Text>
    </BackButtonWrapper>
  );
};
