import React from 'react';
import ReactModal from 'react-modal';
import CloseIcon from '@icons/close.svg';
import { CloseModalButton, MainContent, modalStyles } from './styled';

ReactModal.setAppElement('#___gatsby');

export const Modal = ({ modalOpen, toggleModal, children }) => {
  return (
    <>
      <ReactModal isOpen={modalOpen} onRequestClose={toggleModal} style={modalStyles}>
        <MainContent>{children}</MainContent>
        {modalOpen && (
          <CloseModalButton onClick={toggleModal}>
            <CloseIcon />
          </CloseModalButton>
        )}
      </ReactModal>
    </>
  );
};
