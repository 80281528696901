import React, { useState } from 'react';
import { MDXProvider } from '@mdx-js/react';
import { Box, Flex, Heading, Text } from 'rebass';
import { QueryCache, ReactQueryCacheProvider } from 'react-query';
import {
  Alert,
  BankTransferWalkthrough,
  CallbackHMACCalculator,
  Card,
  CodeHeader,
  Footer,
  HMACCalculator,
  Iframe,
  MDLink,
  Modal,
  Nav,
  PaymentWalkthrough,
  Sidebar,
  Table,
  WalletTransferWalkthrough,
} from '@components';
import {
  Wrapper,
  Main,
  Orderedlist,
  Unorderedlist,
  HR,
  CardGrid,
  OrderedListSimple,
  StyledTextIndent,
} from '@styles/mdx-styles';
import ThemeLayout from './themeLayout';

const queryCache = new QueryCache();

const shortcodes = {
  Alert,
  BankTransferWalkthrough,
  Box,
  Card,
  CardGrid,
  CodeHeader,
  Flex,
  Modal,
  OrderedListSimple,
  StyledTextIndent,
  h1: (props) => <Heading {...props} as="h1" fontSize={['2xl', null, '3xl']} mb="2rem" />,
  h2: (props) => <Heading {...props} as="h2" fontSize={['xl', null, '2xl']} mb="2rem" />,
  h3: (props) => <Heading {...props} as="h3" fontSize={['lg', null, 'xl']} mb="2rem" />,
  h4: (props) => <Heading {...props} as="h4" fontSize={['lg', null, 'xl']} mb="2rem" />,
  HMACCalculator,
  CallbackHMACCalculator,
  hr: () => <HR as="hr" />,
  Iframe,
  MDLink,
  ol: (props) => <Orderedlist {...props} />,
  p: (props) => <Text {...props} as="p" variant="body" mb="3rem" />,
  PaymentWalkthrough,
  table: (props) => <Table {...props} />,
  tbody: (props) => <Table.Body {...props} />,
  td: (props) => <Table.Td {...props} />,
  Text,
  th: (props) => <Table.Th {...props} />,
  thead: (props) => <Table.Head {...props} />,
  ul: (props) => <Unorderedlist {...props} />,
  WalletTransferWalkthrough,
};

export default function Layout({ children }) {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => setSidebarOpen((v) => !v);

  return (
    <ReactQueryCacheProvider queryCache={queryCache}>
      <ThemeLayout>
        <MDXProvider components={shortcodes}>
          <Wrapper>
            <Nav {...{ sidebarOpen, toggleSidebar }} />
            <Sidebar {...{ sidebarOpen, toggleSidebar }} />

            <Main>
              {children}
              <Footer />
            </Main>
          </Wrapper>
        </MDXProvider>
      </ThemeLayout>
    </ReactQueryCacheProvider>
  );
}
