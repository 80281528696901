import React from 'react';
import { Box } from 'rebass';
import { Button, Subtitle, Title } from '../styled';

export const Step1 = ({ goToNext }) => {
  return (
    <>
      <Box mb="1.5rem">
        <Title>Authenticate</Title>
        <Subtitle>
          Authenticate all API request calls with the required keys/HMAC signature and merchant ID specified in your
          headers.
        </Subtitle>
      </Box>
      <Button onClick={goToNext} mt="auto">
        Next
      </Button>
    </>
  );
};
