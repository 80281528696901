import styled from '@emotion/styled';

import { FormGroup } from './group';
import { Input } from './input';
import { Label } from './label';
import { Select } from './select';
import { Text } from './text';
import { Textarea } from './textarea';

const Form = styled.form``;

Form.Group = FormGroup;
Form.Input = Input;
Form.Label = Label;
Form.Select = Select;
Form.Text = Text;
Form.Textarea = Textarea;

export { Form };
