import React from 'react';
import config from '@config';

export const MDLink = ({ text }) => {
  return (
    <a href={config.merchantDashboard} target="_blank" rel="noopener noreferrer">
      {text}
    </a>
  );
};

MDLink.defaultProps = {
  text: 'open.opayweb.com',
};
