import config from '@config';
import { generateReferenceID } from '@utils';

const baseUrl = `${config.production}/`;

export const step1Curl = `curl --location --request POST '${baseUrl}countries' 
--header 'MerchantId: merchant_id' 
--header 'Authorization: Bearer public_key' 
--header 'Content-Type: application/json' 
`;

export const step2Curl = ({ countryCode }) => `curl --location --request POST '${baseUrl}banks' 
--header 'MerchantId: merchant_id' 
--header 'Authorization: Bearer public_key' 
--header 'Content-Type: application/json' 
--data-raw '{
    "countryCode": "${countryCode}"
}'
`;

export const step3Curl = ({
  countryCode,
  bankAccountNo,
  bankCode,
}) => `curl --location --request POST '${baseUrl}verification/accountNumber/resolve' 
--header 'MerchantId: merchant_id' 
--header 'Authorization: Bearer public_key' 
--header 'Content-Type: application/json' 
--data-raw '{
    "bankAccountNo": "${bankAccountNo}",
    "bankCode": "${bankCode}",
    "countryCode": "${countryCode}"
}'
`;

export const step5Curl = ({
  accountName,
  amount,
  bankAccountNo,
  bankCode,
  countryCode,
  reason,
}) => `curl --location --request POST '${baseUrl}transfer/toBank' 
--header 'MerchantId: merchant_id' 
--header 'Authorization: Bearer signature' 
--header 'Content-Type: application/json' 
--data-raw '{
    "amount": "${amount}",
    "country": "${countryCode}",
    "currency": "NGN",
    "reason": "${reason}",
    "receiver": {
        "bankAccountNumber" :"${bankAccountNo}",
        "bankCode" :"${bankCode}",
        "name" :"${accountName}"
    },
    reference: "${generateReferenceID()}"
}'
`;

export const step7Curl = ({ orderNo, reference }) => `curl --location --request POST '${baseUrl}status/toBank' 
--header 'MerchantId: merchant_id' 
--header 'Authorization: Bearer signature' 
--header 'Content-Type: application/json' 
--data-raw '{
    "orderNo": "${orderNo}",
    "reference": "${reference}"
}'
`;
