import React from 'react';
import SuccessIcon from '@icons/success.svg';
import { BackButton, Button, Subtitle, Title } from '../styled';
import { Flex } from 'rebass';

export const Step4 = ({ goToPrev, goToNext }) => {
  return (
    <>
      <BackButton onClick={goToPrev} />
      <Title>Transfer Completed</Title>

      <Flex alignItems="center" justifyContent="center" my="3rem">
        <SuccessIcon />
      </Flex>

      <Subtitle textAlign="center" mb="3rem">
        You have successfully completed the OPay transfer-to-bank account flow.
      </Subtitle>
      <Button onClick={goToNext} mt="auto">
        Done
      </Button>
    </>
  );
};
