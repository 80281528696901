import { breakpoints } from './breakpoints';
import { colors } from './colors';
import { fonts } from './fonts';
import { mq } from './media-query';

const theme = {
  breakpoints: [...Object.values(breakpoints)],
  colors,
  ...fonts,
  mq,
};

export default theme;
