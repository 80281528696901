const config = {
  sandboxURL: process.env.GATSBY_SANDBOX_PROXY_URL,
  utilityURL: process.env.GATSBY_UTILITY_PROXY_URL,
  sandbox: 'http://sandbox-cashierapi.opayweb.com/api/v3',
  production: 'https://cashierapi.opayweb.com/api/v3',
  initializeSandbox: 'http://sandbox-cashierapi.opayweb.com/api/v3/cashier/initialize',
  initializeProduction: 'https://cashierapi.opayweb.com/api/v3/cashier/initialize',
  merchantDashboard: 'https://open.opayweb.com',
  merchantRegister: `https://open.opayweb.com/login?type=signup`,
};

export default config;
