import axios from 'axios';
import config from '@config';

const create = () => {
  const api = axios.create({
    baseURL: config.sandboxURL,
    headers: {
      Authorization: `Bearer OPAYPUB15858807854340.978026707847626`,
      MerchantId: 256620040312000,
    },
  });

  return {
    initializeCashier: (body) => api.post('/cashier/initialize', body).then(({ data }) => data),
    getBanks: (body) => api.post('/banks', body).then(({ data }) => data.data),
    getCountries: () => api.post('/countries').then(({ data }) => data.data),
    transferToBank: ({ body, headers }) => api.post('/transfer/toBank', body, { headers }).then(({ data }) => data),
    transferToBankStatus: ({ body, headers }) =>
      api.post('/transfer/status/toBank', body, { headers }).then(({ data }) => data),
    transferToWallet: ({ body, headers }) => api.post('/transfer/toWallet', body, { headers }).then(({ data }) => data),
    transferToWalletStatus: ({ body, headers }) =>
      api.post('/transfer/status/toWallet', body, { headers }).then(({ data }) => data),
    validateAccountNumber: (body) => api.post('/verification/accountNumber/resolve', body).then(({ data }) => data),
  };
};

const sandbox = create();

export { sandbox };
