import React from 'react';
import { Box, Flex } from 'rebass';
import styled from '@emotion/styled/macro';
import { Link } from 'gatsby';
import { Rating } from '../rating';

const Wrapper = styled(Flex)`
  border-top: 1px solid rgba(165, 172, 192, 0.2);
  flex-direction: column;
  margin-top: auto;
  padding-top: 5rem;
  align-items: flex-start;
`;

const List = styled.ul`
  padding: 0;
  margin: 0;
`;

const ListItem = styled.li`
  list-style: none;

  &:not(:last-child) {
    margin-bottom: 15px;
  }
`;

const ListTitle = styled.p`
  color: ${({ theme }) => theme.colors.primary};
  font-size: ${({ theme }) => theme.fontSizes.sm};
  font-weight: bold;
`;

const ListLink = styled(Link)`
  color: ${({ theme }) => theme.colors.primary};
  font-size: ${({ theme }) => theme.fontSizes.sm};
  font-weight: normal;
  text-decoration: none;
`;

export const Footer = () => {
  return (
    <Wrapper as="footer">
      <Flex mx="-15px" mb="60px">
        <Box px="15px" width={['100%', '100%', '200px']}>
          <List>
            <ListItem>
              <ListTitle>Community</ListTitle>
            </ListItem>

            <ListItem>
              <ListLink as="a" href="https://opayweb.com/" target="_blank" rel="noreferrer noopener">
                Official Website
              </ListLink>
            </ListItem>

            <ListItem>
              <ListLink as="a" href="https://www.facebook.com/opay.ng/" target="_blank" rel="noreferrer noopener">
                Facebook
              </ListLink>
            </ListItem>

            <ListItem>
              <ListLink as="a" href="https://www.linkedin.com/company/opay/" target="_blank" rel="noreferrer noopener">
                Linkedln
              </ListLink>
            </ListItem>

            <ListItem>
              <ListLink as="a" href="https://twitter.com/OPay_NG/" target="_blank" rel="noreferrer noopener">
                Twitter
              </ListLink>
            </ListItem>
          </List>
        </Box>
      </Flex>

      <Rating />
    </Wrapper>
  );
};
