/*
    code style from : https://github.com/bchiang7/v4/blob/main/src/styles/PrismStyles.js
*/

import { css } from '@emotion/core';

const prismColors = {
  bg: `#2C2345`,
  blue: `#5ccfe6`,
  comment: `#8695b799`,
  green: `#bae67e`,
  grey: `#a2aabc`,
  lineHighlight: `#1d2d50`,
  orange: `#ffae57`,
  purple: `#c3a6ff`,
  red: `#F3628C`,
  variable: '#FFB600',
  white: '#fff',
  yellow: `#ffd580`,
};

const PrismStyles = css`
  .gatsby-highlight {
    background-color: ${prismColors.bg};
    border-radius: 6px;
    color: ${prismColors.variable};
    margin: 3rem 0;
    overflow: auto;
    padding: 3rem 2rem;
    position: relative;

    &.m-0 {
      margin: 0;
    }
  }

  .gatsby-highlight code[class*='language-'],
  .gatsby-highlight pre[class*='language-'] {
    font-size: 14px;
    height: auto !important;
    hyphens: none;
    line-height: 1.5;
    tab-size: 2;
    white-space: pre;
    word-break: normal;
    word-spacing: normal;
    word-wrap: normal;
  }

  .gatsby-highlight pre[class*='language-'] {
    background-color: transparent;
    margin: 0;
    padding: 0;
    overflow: initial;
    float: left;
    min-width: 100%;
    padding-top: 2em;

    &.line-numbers {
      padding-top: 0;
      padding-left: 4rem;
    }
  }

  /* Line highlighting */
  .gatsby-highlight-code-line {
    display: block;
    background-color: ${prismColors.lineHighlight};
    border-left: 2px solid ${prismColors.green};
    padding-left: calc(1em + 2px);
    padding-right: 1em;
    margin-right: -1.35em;
    margin-left: -1.35em;
  }

  /* Language badges */
  .gatsby-highlight pre[class*='language-']::before {
    background: ${prismColors.grey};
    color: ${prismColors.white};
    font-size: 14px;
    left: 0;
    letter-spacing: 0.1em;
    line-height: 24px;
    padding: 1rem 2rem;
    position: absolute;
    text-transform: uppercase;
    top: 0;
    width: 100%;
  }

  .gatsby-highlight pre[class='language-javascript']::before {
    content: 'js';
  }
  .gatsby-highlight pre[class='language-java']::before {
    content: 'java';
  }
  .gatsby-highlight pre[class='language-js']::before {
    content: 'js';
  }
  .gatsby-highlight pre[class='language-jsx']::before {
    content: 'jsx';
  }
  .gatsby-highlight pre[class='language-graphql']::before {
    content: 'GraphQL';
  }
  .gatsby-highlight pre[class='language-html']::before {
    content: 'html';
  }
  .gatsby-highlight pre[class='language-css']::before {
    content: 'css';
  }
  .gatsby-highlight pre[class='language-mdx']::before {
    content: 'mdx';
  }
  .gatsby-highlight pre[class='language-shell']::before {
    content: 'shell';
  }
  .gatsby-highlight pre[class='language-sh']::before {
    content: 'sh';
  }
  .gatsby-highlight pre[class='language-bash']::before {
    content: 'bash';
  }
  .gatsby-highlight pre[class='language-yaml']::before {
    content: 'yaml';
  }
  .gatsby-highlight pre[class='language-markdown']::before {
    content: 'md';
  }
  .gatsby-highlight pre[class='language-json']::before,
  .gatsby-highlight pre[class='language-json5']::before {
    content: 'json';
  }
  .gatsby-highlight pre[class='language-diff']::before {
    content: 'diff';
  }
  .gatsby-highlight pre[class='language-text']::before {
    content: 'text';
  }
  .gatsby-highlight pre[class='language-flow']::before {
    content: 'flow';
  }
  /* Prism Styles */
  .token {
    display: inline;
  }
  .token.comment,
  .token.block-comment,
  .token.prolog,
  .token.doctype,
  .token.cdata {
    color: ${prismColors.comment};
  }
  .token.punctuation {
    color: ${prismColors.grey};
  }
  .token.namespace,
  .token.deleted {
    color: ${prismColors.red};
  }
  .token.function-name,
  .token.function,
  .token.class-name,
  .token.constant,
  .token.symbol {
    color: ${prismColors.yellow};
  }
  .token.attr-name,
  .token.operator,
  .token.rule {
    color: ${prismColors.orange};
  }
  .token.keyword,
  .token.boolean,
  .token.number,
  .token.property {
    color: ${prismColors.variable};
  }
  .token.tag,
  .token.selector,
  .token.important,
  .token.atrule,
  .token.builtin,
  .token.entity,
  .token.url {
    color: ${prismColors.blue};
  }
  .token.string,
  .token.char,
  .token.attr-value,
  .token.regex,
  .token.variable,
  .token.inserted {
    color: ${prismColors.red};
  }
  .token.important,
  .token.bold {
    font-weight: 600;
  }
  .token.italic {
    font-style: italic;
  }
  .token.entity {
    cursor: help;
  }
  .namespace {
    opacity: 0.7;
  }
`;

export default PrismStyles;
