import React from 'react';
import { Form } from '@components/form';
import { BackButton, Button, Subtitle, Title } from '../styled';

const options = [
  {
    value: 'MERCHANT',
    label: 'Merchant',
  },

  {
    value: 'USER',
    label: 'User',
  },
];

export const Step2 = ({ form, goToNext, goToPrev, onInputChange }) => {
  const renderType = () => {
    switch (form.type) {
      case 'MERCHANT':
        return (
          <>
            <Form.Group>
              <Form.Label>Account name</Form.Label>
              <Form.Input placeholder="John Doe" name="name" value={form.name} disabled />
            </Form.Group>

            <Form.Group>
              <Form.Label>Merchant ID</Form.Label>
              <Form.Input placeholder="8976545678" name="merchantId" value={form.merchantId} disabled />
            </Form.Group>

            <Button type="button" onClick={goToNext} disabled={!form.name || !form.merchantId}>
              Verify OPay Wallet
            </Button>
          </>
        );

      case 'USER':
        return (
          <>
            <Form.Group>
              <Form.Label>Account name</Form.Label>
              <Form.Input placeholder="John Doe" name="name" value={form.name} disabled />
            </Form.Group>

            <Form.Group>
              <Form.Label>Phone number</Form.Label>
              <Form.Input placeholder="80000000" name="phoneNumber" disabled value={form.phoneNumber} />
            </Form.Group>

            <Button type="button" onClick={goToNext} disabled={!form.name || !form.phoneNumber}>
              Verify OPay Wallet
            </Button>
          </>
        );

      default:
        return null;
    }
  };

  return (
    <>
      <BackButton onClick={goToPrev} />
      <Title>Wallet details</Title>

      <Subtitle mb="3rem">Select the merchant/user wallet details.</Subtitle>

      <Form>
        <Form.Group>
          <Form.Label>Wallet type</Form.Label>
          <Form.Select value={form.type} name="type" onChange={onInputChange}>
            <option value="" disabled>
              Select wallet type
            </option>
            {options.map((option) => (
              <option value={option.value} key={option.value}>
                {option.label}
              </option>
            ))}
          </Form.Select>
        </Form.Group>

        {renderType()}
      </Form>
    </>
  );
};
