import React from 'react';
import { Box } from 'rebass';
import styled from '@emotion/styled';

const Wrapper = styled(Box)`
  padding-top: 56.25%;
  position: relative;

  iframe {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
`;

export const Iframe = (props) => {
  return (
    <Wrapper>
      <iframe frameBorder="0" {...props} />
    </Wrapper>
  );
};
