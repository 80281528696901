export const colors = {
  header: '#2C3E50',
  background: '#F9FBFC',
  banner: '#E8FAF5',
  black: '#000000',
  crimson: 'crimson',
  dark: '#171231',
  orange: '#e79924',
  primary: '#34495E',
  purple: '#210f60',
  secondary: '#1DCF9F',
  transparent: 'transparent',
  white: '#ffffff',
};
