import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled/macro';
import { Flex, Text } from 'rebass';
import CloseIcon from '@icons/close.svg';
import { css } from '@emotion/core';
import { useMutation } from 'react-query';
import { utility } from '@services/api/utility';
import { Form } from '../form';
import { Button } from '../Button';

const Wrapper = styled.div`
  background: #ffffff;
  border-radius: 6px;
  bottom: 100%;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.07);
  max-width: 280px;
  opacity: 0;
  padding: 30px 20px;
  pointer-events: none;
  position: absolute;
  right: 0;
  transform: translateY(50%);
  transition: all 0.2s;
  visibility: hidden;
  width: 100%;

  ${({ isOpen }) =>
    isOpen &&
    css`
      opacity: 1;
      pointer-events: auto;
      transform: translateY(0);
      visibility: visible;
    `}

  ${({ theme }) => theme.mq.lg`
    bottom: 0;
    left: calc(100% + 20px);  
    max-width: 320px;
  `}
`;

const CloseButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  position: absolute;
  right: 20px;
  top: 20px;
`;

export const CommentModal = ({ isOpen, closeModal }) => {
  const [data, setData] = useState({
    email: '',
    comment: '',
  });
  const [error, setError] = useState({
    email: '',
    comment: '',
  });

  const [done, setDone] = useState(false);

  const [createComment, { isLoading }] = useMutation(utility.createComment);

  const resetForm = () => {
    setError({
      email: '',
      comment: '',
    });
    setData({
      email: '',
      comment: '',
    });
    setDone(false);
  };

  useEffect(() => {
    if (!isOpen) {
      resetForm();
    }
    
  }, [isOpen]);

  useEffect(() => {
    let timeout;

    if (done) {
      timeout = setTimeout(() => {
        closeModal()
      }, 3000);
    }

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [done]);

  const onChange = (e) => {
    setError({
      ...error,
      [e.target.name]: '',
    });

    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const onBlur = (e) => {
    if (e.target.name === 'email') {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(e.target.value)) {
        return;
      } else {
        setError({
          ...error,
          [e.target.name]: `Please enter a valid email!`,
        });
      }
    } else if (e.target.value === '') {
      setError({
        ...error,
        [e.target.name]: `Please enter your ${e.target.name}!`,
      });
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setError({
      email: '',
      comment: '',
    });
    try {
      if (typeof window !== `undefined`) {
        data.path = location.origin + location.pathname;
      }
      await createComment(data);
      setDone(true);
    } catch (e) {
      console.log({ e });
    }
  };

  const validateForm = () => {
    if (data.email !== '' && data.comment !== '' && error.email === '' && error.comment === '') {
      return false;
    } else return true;
  };

  return (
    <Wrapper isOpen={isOpen}>
      {done ? (
        <Text variant="body">Thank you for your comment!</Text>
      ) : (
        <>
          <Text as="p" variant="smallBody" fontSize="md" mb="25px" fontWeight="semibold">
            Help us make this page better
          </Text>

          <Text as="p" variant="smallBody" mb="8px">
            Please leave a comment on how we can improve your experience.
          </Text>

          <Form onSubmit={onSubmit}>
            <Form.Group>
              <Form.Label>Contact email</Form.Label>
              <Form.Input name="email" type="email" onChange={onChange} value={data.email} onBlur={onBlur} />
              {error.email && <Text variant="error">{error.email}</Text>}
            </Form.Group>
            <Form.Group>
              <Form.Label>Comment</Form.Label>
              <Form.Textarea name="comment" value={data.comment} onChange={onChange} onBlur={onBlur} />
              {error.comment && <Text variant="error">{error.comment}</Text>}
            </Form.Group>

            <Flex justifyContent="flex-end">
              <Button disabled={validateForm()} loading={isLoading} onClick={onSubmit}>
                Send Comment
              </Button>
            </Flex>
          </Form>
        </>
      )}

      <CloseButton onClick={() => closeModal()}>
        <CloseIcon />
      </CloseButton>
    </Wrapper>
  );
};
