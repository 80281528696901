import { useState } from 'react';

export function useSteps(initialStep = 1, steps = 3) {
  const [currentStep, goToStep] = useState(initialStep);

  const goToNext = () => {
    if (currentStep < steps) {
      goToStep((v) => v + 1);
    }
  };

  const goToPrev = () => {
    if (currentStep > 1) {
      goToStep((v) => v - 1);
    }
  };

  return { currentStep, goToNext, goToPrev, goToStep };
}
