import styled from '@emotion/styled';
import { Box } from 'rebass';
import { sidebarWidth } from '@data/sidebar';

export const Wrapper = styled.div`
  padding-top: 80px;

  ${({ theme }) => theme.mq.md`
  padding-top: 65x;
  padding-left: ${sidebarWidth.md};
  `}

  ${({ theme }) => theme.mq.xl`
  padding-left: ${sidebarWidth.xl};
  `}
`;

export const Main = styled.main`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 80px);
  padding: 50px 20px 20px;

  ${({ theme }) => theme.mq.md`
  min-height: calc(100vh - 65px);
  padding: 40px;;
  `}
`;

export const OrderedListSimple = styled.ol`
  counter-reset: custom-counter;
  list-style: none;

  li {
    align-items: baseline;
    color: ${({ theme }) => theme.colors.primary};
    counter-increment: custom-counter;
    flex-direction: row;
    font-size: ${({ theme }) => theme.fontSizes.sm};
    line-height: 1.5;
    padding-left: 20px;
    position: relative;

    &::before {
      content: counter(custom-counter) '.';
      left: 0;
      position: absolute;
      top: 0;
    }

    &:not(:last-child) {
      margin-bottom: 14px;
    }
  }

  > span {
    color: ${({ theme }) => theme.colors.secondary};
  }
`;

export const Orderedlist = styled(OrderedListSimple)`
  counter-reset: custom-counter;
  list-style: none;

  li {
    counter-increment: custom-counter;
    padding-left: 36px;
    &::before {
      align-items: center;
      background: rgba(23, 18, 49, 0.1);
      border-radius: 50%;
      color: ${({ theme }) => theme.colors.primary};
      content: counter(custom-counter);
      display: inline-flex;
      flex-shrink: 0;
      font-size: ${({ theme }) => theme.fontSizes.sm};
      font-weight: normal;
      height: 21px;
      justify-content: center;
      left: 0;
      position: absolute;
      top: 4px;
      width: 21px;
    }
  }
`;

export const Unorderedlist = styled.ul`
  list-style: none;

  &:not(:last-child) {
    margin-bottom: 25px;
  }

  li {
    font-size: ${({ theme }) => theme.fontSizes.sm};
    align-items: baseline;
    color: ${({ theme }) => theme.colors.primary};
    flex-direction: row;
    line-height: 1.5;
    padding-left: 20px;
    position: relative;

    &:not(:last-child) {
      margin-bottom: 6px;
    }

    &::before {
      background: rgba(23, 18, 49, 0.6);
      border-radius: 50%;
      content: '';
      flex-shrink: 0;
      font-size: ${({ theme }) => theme.fontSizes.sm};
      height: 6px;
      left: 4px;
      position: absolute;
      top: 7px;
      width: 6px;
    }
  }
`;

export const HR = styled(Box)`
  border-color: rgba(165, 172, 192, 0.2);
  border-top: none;
  margin-bottom: 46px;
  margin-top: 46px;
`;

export const CardGrid = styled(Box)`
  display: grid;
  width: 100%;
`;

export const StyledTextIndent = styled.span`
  margin-left: 20px;
  padding: 5px;
`;

CardGrid.defaultProps = {
  sx: {
    gridTemplateColumns: ['1fr', null, null, 'repeat(2, 1fr)'],
    gridGap: ['30px', null, null, '20px'],
  },
};
