import React, { useEffect } from 'react';
import { Box } from 'rebass';
import Prism from 'prismjs';
import styled from '@emotion/styled';

const Wrapper = styled(Box)`
  height: 100%;
  margin: 0;

  pre {
    padding-top: 0 !important;
  }

  code {
    font-family: monospace;
    font-size: ${({ theme }) => theme.fontSizes.sm};
  }
`;

export const PrismCode = ({ code, language, plugins }) => {
  useEffect(() => {
    Prism.highlightAll();
  }, [language, code]);

  return (
    <Wrapper className="gatsby-highlight m-0" height="100%">
      <pre className={!plugins ? '' : plugins.join(' ')}>
        <code className={`language-${language}`}>{code}</code>
      </pre>
    </Wrapper>
  );
};
