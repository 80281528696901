import React, { useState } from 'react';
import { Flex, Text } from 'rebass';
import { generateCallbackPrivateKey } from '@utils/generators';

import { Form } from './form';
import { Button } from './Button';

const INITIAL_FORM = {
  secret: '',
  data: '',
  result: '',
};

export const CallbackHMACCalculator = () => {
  const [form, setForm] = useState({ ...INITIAL_FORM });
  const [errors, setErrors] = useState({ ...INITIAL_FORM });

  const onChange = (e) => {
    const { name, value } = e.target;
    setForm((v) => ({ ...v, [name]: value }));
  };

  const validForm = () => {
    setErrors({ ...INITIAL_FORM });

    const newErrors = {};
    if (!form.secret) {
      newErrors.secret = "Secret can't be empty";
    }
    if (!form.data) {
      newErrors.data = "Data can't be empty";
    }
    setErrors(newErrors);
    return !Object.keys(newErrors).length;
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (validForm()) {
      try {
        const stringified = JSON.stringify(form.data);
        const parsed = JSON.parse(stringified);
        const result = generateCallbackPrivateKey(parsed, form.secret);

        setForm((v) => ({
          ...v,
          result,
        }));
      } catch (e) {
        setErrors((v) => ({ ...v, data: 'Invalid data' }));
      }
    }
  };

  return (
    <Flex flexWrap="nowrap" flexDirection="column" maxWidth="40rem">
      <Form onSubmit={onSubmit}>
        <Form.Group>
          <Form.Label>Secret Key</Form.Label>
          <Form.Input name="secret" onChange={onChange} value={form.secret} />

          {errors.secret && <Text variant="error">{errors.secret}</Text>}
        </Form.Group>

        <Form.Group>
          <Form.Label>JSON Object/Payload</Form.Label>
          <Form.Textarea name="data" onChange={onChange} value={form.data} rows="7" />

          {errors.data && <Text variant="error">{errors.data}</Text>}
        </Form.Group>

        <Form.Group>
          <Button onClick={onSubmit}>Generate</Button>
        </Form.Group>

        {form.result ? (
          <Form.Group>
            <Form.Label>Computed HMAC-SHA3-512 Signature</Form.Label>
            <Form.Input readOnly name="result" value={form.result} />
          </Form.Group>
        ) : null}

        {/* {form.sortedData ? (
          <Form.Group>
            <Form.Label>Sorted Data</Form.Label>
            <Form.Textarea readOnly name="sortedData" value={form.sortedData} rows="7" />
          </Form.Group>
        ) : null} */}
      </Form>
    </Flex>
  );
};
