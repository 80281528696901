import config from '@config';
import { generateReferenceID } from '@utils';

const baseUrl = `${config.production}/`;

export const step1Curl = `curl --location --request POST '${baseUrl}countries' 
--header 'MerchantId: merchant_id' 
--header 'Authorization: Bearer public_key' 
--header 'Content-Type: application/json'`;

export const step2Curl = ({
  amount,
  name,
  reason,
  type,
  merchantId,
  phoneNumber,
}) => `curl --location --request POST '${baseUrl}transfer/toWallet' 
--header 'MerchantId: merchant_id' 
--header 'Authorization: Bearer signature' 
--header 'Content-Type: application/json' 
--data-raw '{
    "amount": "${amount}",
    "country": "NG",
    "currency": "NGN",
    "reason": "${reason}",
    "receiver": {${type === 'MERCHANT' ? `\n        "merchantId" :"${merchantId}",` : ''}
        "name" :"${name}",${type === 'USER' ? `\n        "phoneNumber" :"${phoneNumber}",` : ''}
        "type" :"${type}"
    },
    reference: "${generateReferenceID()}"
}'
`;

export const step5Curl = ({ orderNo, reference }) => `curl --location --request POST '${baseUrl}status/toWallet' 
--header 'MerchantId: merchant_id' 
--header 'Authorization: Bearer signature' 
--header 'Content-Type: application/json' 
--data-raw '{
    "orderNo": "${orderNo}",
    "reference": "${reference}"
}'
`;
