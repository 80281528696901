import styled from '@emotion/styled';

export const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  &:not(:last-child) {
    margin-bottom: 15px;
  }
`;
