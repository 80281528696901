import React from 'react';
import { Link } from 'gatsby';
import { sidebarLinks } from '@data/sidebar';
import Logo from '@icons/logo.svg';
import config from '@config';
import {
  CreateButton,
  LoginButton,
  LinkContent,
  LinkIcon,
  LinksWrapper,
  LinkText,
  LinkWrapper,
  LogoWrapper,
  Overlay,
  SubLinkContent,
  SublinkText,
  SublinkWrapper,
  SubmenuWrapper,
  Wrapper,
} from './styled';
import { Box } from 'rebass';

const isPathActive = (path, subMenu) => {
  return typeof window !== `undefined` && location.pathname.includes(path) && !!subMenu?.length;
};

const Sublink = ({ text, path, subMenu, toggleSidebar }) => {
  return (
    <SublinkWrapper onClick={toggleSidebar}>
      <SubLinkContent
        activeClassName="active"
        partiallyActive={!!subMenu?.length}
        subMenuPresent={isPathActive(path, subMenu)}
        to={path}
      >
        <SublinkText>{text}</SublinkText>
      </SubLinkContent>

      {isPathActive(path, subMenu) ? (
        <SubmenuWrapper>
          {subMenu.map((subLink) => (
            <Sublink key={subLink.path} {...subLink} />
          ))}
        </SubmenuWrapper>
      ) : null}
    </SublinkWrapper>
  );
};

const CustomLink = ({ text, path, icon, subMenu, toggleSidebar }) => {
  return (
    <LinkWrapper onClick={subMenu === undefined && toggleSidebar}>
      <LinkContent
        activeClassName="active"
        partiallyActive={path === '/' ? false : true}
        subMenuPresent={isPathActive(path, subMenu)}
        to={path}
      >
        <LinkIcon as={icon} />
        <LinkText>{text}</LinkText>
      </LinkContent>

      {isPathActive(path, subMenu) ? (
        <SubmenuWrapper as="ul">
          {subMenu.map((subLink) => (
            <Sublink key={subLink.path} {...subLink} toggleSidebar={toggleSidebar} />
          ))}
        </SubmenuWrapper>
      ) : null}
    </LinkWrapper>
  );
};

export const Sidebar = ({ sidebarOpen, toggleSidebar }) => {
  return (
    <>
      <Overlay sidebarOpen={sidebarOpen} onClick={toggleSidebar} />
      <Wrapper as="aside" sidebarOpen={sidebarOpen}>
        <LogoWrapper>
          <Link to="/" aria-label="Home link">
            <Logo />
          </Link>
        </LogoWrapper>
        <LinksWrapper as="ul" mb="">
          {sidebarLinks.map((link) => (
            <CustomLink key={link.path} {...link} toggleSidebar={toggleSidebar} />
          ))}
        </LinksWrapper>

        <Box mt="4rem" display={['block', null, 'none']}>
          <CreateButton
            as="a"
            href={config.merchantRegister}
            justifyContent="center"
            rel="noopener noreferrer"
            target="_blank"
          >
            Create Account
          </CreateButton>
        </Box>

        <Box mt="1rem" display={['block', null, 'none']}>
          <LoginButton
            as="a"
            href={config.merchantDashboard}
            justifyContent="center"
            rel="noopener noreferrer"
            target="_blank"
          >
            Login
          </LoginButton>
        </Box>
      </Wrapper>
    </>
  );
};
