import { Link as GatsbyLink } from 'gatsby';
import gs1 from "../../../../src/docs/images/gs1.svg";
import gs2 from "../../../../src/docs/images/gs2.svg";
import gs3 from "../../../../src/docs/images/gs3.svg";
import gs4 from "../../../../src/docs/images/gs4.svg";
import * as React from 'react';
export default {
  GatsbyLink,
  gs1,
  gs2,
  gs3,
  gs4,
  React
};