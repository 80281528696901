import React from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/core';
import { Heading, Text } from 'rebass';

const bounce = keyframes`
  0%   { transform: translateY(0); }
  50%   { transform: translateY(1px); }
  100% { transform: translateY(0); }
`;

const bounceHigher = keyframes`
  0%   { transform: translateY(0); }
  50%  { transform: translateY(2px); }
  100% { transform: translateY(0); }
`;

export const Card = ({ caption, image, title, ...props }) => {
  return (
    <Container {...props}>
      <CardWrapper>
        {image && (
          <CardTopBox>
            <HeaderImage as={image} alt={title} />
          </CardTopBox>
        )}
        <CardBottomBox>
          <Heading as="h3" fontSize={['lg', null, 'xl']} color="secondary" mb="8px">
            {title}
          </Heading>
          <Text variant="smallBody">{caption}</Text>
        </CardBottomBox>
      </CardWrapper>
    </Container>
  );
};

const Container = styled.div`
  display: block;
  height: 100%;
  text-decoration: none;
  width: 100%;

  &:nth-child(1) {
    svg #animate {
      animation-delay: 0.5s;
    }
  }

  &:nth-child(1) {
    svg #animate {
      animation-delay: 1s;
    }
  }
`;

const CardWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.white}80;
  border-radius: 6px;
  border: 1px solid rgba(165, 172, 192, 0.3);
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.07);
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const CardTopBox = styled.div`
  border-radius: 6px;
  width: 100%;

  svg #animate {
    animation: ${bounce} 2s infinite;
  }

  &:hover {
    svg #animate {
      animation: ${bounceHigher} 2s infinite;
    }
  }
`;

const HeaderImage = styled.svg`
  height: auto;
  width: 100%;
`;

const CardBottomBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 20px;
`;
