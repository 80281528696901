import React from 'react';
import { ThemeProvider } from 'emotion-theming';
import { Global } from '@emotion/core';
import theme from '@theme';
import GlobalStyle from '@styles/GlobalStyles';

export default function ThemeLayout({ children }) {
  return (
    <ThemeProvider theme={theme}>
      <>
        <Global styles={GlobalStyle} />
        {children}
      </>
    </ThemeProvider>
  );
}
