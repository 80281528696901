const bodyText = {
  color: 'primary',
  fontSize: 'sm',
  fontWeight: 'normal',
  lineHeight: '1.5',
};

const heading = {
  color: 'header',
  fontSize: ['2xl', null, '4xl'],
  fontWeight: 'semibold',
  lineHeight: '1.4',
};

export const fonts = {
  fonts: {
    heading: 'Graphik,sans-serif',
    body: "'Roboto', sans-serif",
    nav: 'Graphik,sans-serif',
  },
  fontSizes: {
    xs: '12px',
    sm: '14px',
    md: '16px',
    lg: '18px',
    xl: '20px',
    '2xl': '24px',
    '3xl': '28px',
    '4xl': '36px',
    '5xl': '48px',
    '6xl': '64px',
    '7xl': '72px',
  },
  textAlign: {
    left: 'left',
    center: 'center',
    right: 'right',
  },
  fontWeights: {
    light: 300,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
  },
  text: {
    body: {
      ...bodyText,
    },
    subHeaderBody: {
      ...bodyText,
      fontSize: ['md', null, 'lg'],
    },
    inlineHeader: {
      ...bodyText,
      fontFamily: 'heading',
      fontSize: ['lg', null, '2xl'],
      fontWeight: 'semibold',
    },
    heading: {
      ...heading,
    },
    smallBody: {
      ...bodyText,
    },
    error: {
      color: 'crimson',
      fontSize: 'sm',
      mt: '5px',
    },
  },
};
