import React, { useState } from 'react';
import { PrismCode } from '@components';
import { step1Curl, step2Curl } from '@data/walkthrough/payment';
import { useSteps } from '@hooks';
import { openInNewTab } from '@utils';
import { Left, Right, Wrapper } from '../styled';
import { Step1 } from './step1';
import { Step2 } from './step2';
import { Step3 } from './step3';
import { Step4 } from './step4';

const INITIAL_FORM = {
  number: '2348131393827',
  amount: '',
  time: '',
};

export const PaymentWalkthrough = () => {
  const { currentStep, goToNext, goToPrev, goToStep } = useSteps(1, 4);
  const [form, setForm] = useState({ ...INITIAL_FORM });
  const [apiResp, setApiResp] = useState('');

  const onSubmitSuccess = (resp) => {
    setApiResp(resp);
  };

  const completePayment = () => {
    openInNewTab(apiResp?.data?.cashierUrl);
    goToNext();
  };

  const restartWalkthrough = () => goToStep(1);

  const showCurrentStep = () => {
    switch (currentStep) {
      case 1:
        return <Step1 {...{ currentStep, goToNext }} />;
      case 2:
        return <Step2 {...{ currentStep, form, goToNext, goToPrev, onSubmitSuccess, setForm }} />;
      case 3:
        return <Step3 {...{ completePayment, currentStep, goToPrev }} />;
      case 4:
        return <Step4 {...{ currentStep, goToPrev, restartWalkthrough }} />;
      default:
        return <Step1 {...{ currentStep, goToNext, goToPrev }} />;
    }
  };

  const currentCodeSnippet = () => {
    switch (currentStep) {
      case 1:
        return step1Curl;
      case 2:
        return step2Curl(form);
      case 3:
        return JSON.stringify(apiResp, null, 2);
      default:
        return step1Curl;
    }
  };

  return (
    <Wrapper>
      <Left>{showCurrentStep()}</Left>
      <Right>
        <PrismCode language="javascript" code={currentCodeSnippet()} />
      </Right>
    </Wrapper>
  );
};
