import React, { useEffect, useMemo, useState } from 'react';
import { PrismCode } from '@components';
import { useSteps } from '@hooks';
import { sandbox } from '@services/api/sandbox';
import { step1Curl, step2Curl, step3Curl, step5Curl, step7Curl } from '@data/walkthrough/bankTransfer';
import { Left, Right, Wrapper } from '../styled';
import { Step1 } from './Step1';
import { Step2 } from './Step2';
import { Step3 } from './Step3';
import { Step4 } from './Step4';
import { Step5 } from './Step5';
import { Step6 } from './Step6';
import { Step7 } from './Step7';
import { Step8 } from './Step8';

const INITIAL_FORM = {
  accountName: '',
  amount: '',
  bankAccountNo: '',
  bankCode: '',
  countryCode: '',
  orderNo: '',
  reason: '',
  reference: '',
};

export const BankTransferWalkthrough = () => {
  const [form, setForm] = useState({ ...INITIAL_FORM });
  const [stepResponse, setStepResponse] = useState({});
  const [countries, setCountries] = useState([]);
  const [banks, setBanks] = useState([]);

  const { currentStep, goToNext, goToPrev, goToStep } = useSteps(1, 8);

  const updateStepResponse = (key, value) => {
    setStepResponse((v) => ({
      ...v,
      [key]: JSON.stringify(value, null, 2),
    }));
  };

  useEffect(() => {
    (async () => {
      const data = await sandbox.getCountries();
      setCountries(data);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (form.countryCode) {
        const data = await sandbox.getBanks({ countryCode: form.countryCode });
        setBanks(data);
      }
    })();
  }, [form.countryCode]);

  const restartWalkthrough = () => {
    setForm({ ...INITIAL_FORM });
    setStepResponse({});
    goToStep(1);
  };

  const updateForm = (key, value) => {
    setForm((v) => ({ ...v, [key]: value }));
  };

  const onInputChange = (e) => {
    const { name, value } = e.target;
    updateForm(name, value);
  };

  const stepProps = useMemo(
    () => ({
      banks,
      countries,
      form,
      goToNext,
      goToPrev,
      onInputChange,
      restartWalkthrough,
      updateForm,
    }),
    [banks, countries, form, goToNext, goToPrev, onInputChange, restartWalkthrough, updateForm],
  );

  const showCurrentStep = () => {
    switch (currentStep) {
      case 1:
        return <Step1 {...stepProps} />;
      case 2:
        return <Step2 {...stepProps} />;
      case 3:
        return <Step3 {...stepProps} updateStepResponse={updateStepResponse} />;
      case 4:
        return <Step4 {...stepProps} />;
      case 5:
        return <Step5 {...stepProps} updateStepResponse={updateStepResponse} />;
      case 6:
        return <Step6 {...stepProps} />;
      case 7:
        return <Step7 {...stepProps} updateStepResponse={updateStepResponse} />;
      case 8:
        return <Step8 {...stepProps} />;

      default:
        return <Step1 {...stepProps} />;
    }
  };

  const currentCodeSnippet = () => {
    switch (currentStep) {
      case 1:
        return step1Curl;
      case 2:
        return step2Curl(form);
      case 3:
        return step3Curl(form);

      case 4:
        return stepResponse['3'] || '';

      case 5:
        return step5Curl(form);

      case 6:
        return stepResponse['5'] || '';

      case 7:
        return step7Curl(form);

      case 8:
        return stepResponse['7'] || '';

      default:
        return step1Curl;
    }
  };

  return (
    <Wrapper>
      <Left>{showCurrentStep()}</Left>
      <Right>
        <PrismCode language="javascript" code={currentCodeSnippet()} />
      </Right>
    </Wrapper>
  );
};
