import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { Link } from 'gatsby';
import { Box, Button } from 'rebass';
import { sidebarWidth } from '@data/sidebar';

export const Wrapper = styled(Box)`
  background-color: ${({ theme }) => theme.colors.background};
  height: calc(100vh - 80px);
  max-width: 360px;
  overflow-y: auto;
  padding: 50px 30px;
  position: fixed;
  right: 0;
  top: 80px;
  transform: ${({ sidebarOpen }) => (sidebarOpen ? 'translateX(0)' : 'translateX(100%)')};
  transition: all 0.3s;
  width: 90%;
  z-index: 2;

  ${({ theme }) => theme.mq.md`
    border-right: 1px solid rgba(165, 172, 192, 0.1);
    height: 100vh;
    left: 0;
    padding: 20px 35px;
    padding-right: 10px;
    right: unset;
    top: 0;
    transform:  translateX(0);
    width: ${sidebarWidth.md};

  `}
  ${({ theme }) => theme.mq.xl`
    width: ${sidebarWidth.xl};
  `};
`;

export const Overlay = styled.div`
  background: rgba(48, 48, 48, 0.2);
  content: '';
  height: 100%;
  left: 0;
  opacity: ${({ sidebarOpen }) => (sidebarOpen ? '1' : '0')};
  pointer-events: ${({ sidebarOpen }) => (sidebarOpen ? 'auto' : 'none')};
  position: fixed;
  top: 0;
  transition: all 0.3s;
  width: 100%;
  z-index: 2;

  ${({ theme }) => theme.mq.md`
    background: unset;
`}
`;

export const LogoWrapper = styled(Box)`
  display: none;

  ${({ theme }) => theme.mq.md`
    display: block;
    margin-bottom: 70px;
`}
`;

export const LinksWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 0;
`;

export const LinkBase = styled(Link)`
  align-items: center;
  display: flex;
  flex-direction: row;
  &,
  &:visited {
    color: ${({ theme }) => theme.colors.primary};
  }

  &:hover {
    color: ${({ theme }) => theme.colors.secondary}E6;

    svg {
      path {
        fill: ${({ theme }) => theme.colors.secondary}E6;
      }
    }
  }

  &.active {
    color: ${({ theme }) => theme.colors.secondary};

    svg {
      path {
        fill: ${({ theme }) => theme.colors.secondary};
      }
    }
  }

  text-decoration: none;

  ${({ subMenuPresent }) =>
    subMenuPresent &&
    css`
      margin-bottom: 10px;
    `}
`;

export const LinkWrapper = styled.li`
  list-style: none;
  &:not(:last-child) {
    margin-bottom: 30px;
  }
`;

export const SubLinkContent = styled(LinkBase)``;

export const LinkContent = styled(LinkBase)`
  align-items: center;
`;

export const LinkIcon = styled.svg`
  height: auto;
  margin-right: 10px;
  width: 19px;
`;

export const LinkText = styled.span`
  display: inline-block;
  font-family: ${({ theme }) => theme.fonts.body};
  font-size: ${({ theme }) => theme.fontSizes.sm};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  line-height: 1.5;

  ${({ theme }) => theme.mq.md`
  font-size: ${theme.fontSizes.sm};
  `}
`;

export const SubmenuWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 9px 0 9px 29px;
`;

export const SublinkWrapper = styled.li`
  list-style: none;
  &:not(:last-child) {
    margin-bottom: 18px;
  }
`;

export const SublinkText = styled.span`
  display: inline-block;
  font-family: ${({ theme }) => theme.fonts.body};
  font-size: ${({ theme }) => theme.fontSizes.sm};
  line-height: 1.5;

  ${({ theme }) => theme.mq.md`
  font-size: ${theme.fontSizes.sm};
  `}
`;

export const CreateButton = styled(Button)`
  &,
  &:visited {
    color: ${({ theme }) => theme.colors.white};
  }

  align-items: center;
  background-color: ${({ theme }) => theme.colors.secondary};
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-weight: bold;
  height: 36px;
  outline: none;
`;

export const LoginButton = styled(Button)`
  &,
  &:visited {
    color: ${({ theme }) => theme.colors.secondary};
  }

  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-weight: bold;
  height: 36px;
  outline: none;
  border: 1px solid ${({ theme }) => theme.colors.secondary};
`;
