import styled from '@emotion/styled';
import { Box, Button, Flex } from 'rebass';
import { sidebarWidth } from '@data/sidebar';

export const Wrapper = styled(Box)`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.background};
  border-bottom: 1px solid rgba(165, 172, 192, 0.2);
  display: flex;
  height: 80px;
  left: 0;
  padding: 12px 20px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 3;

  ${({ theme }) => theme.mq.md`
  background-color: ${({ theme }) => theme.colors.transparent};
  height: unset;
  left: ${sidebarWidth.md};
  width: calc(100% - ${sidebarWidth.md});
  padding: 15px 40px;
`}

  ${({ theme }) => theme.mq.xl`
    left: 361px;
    width: calc(100% - ${sidebarWidth.xl});
    padding: 15px 40px;
  `}
`;

export const LogoWrapper = styled(Box)`
  display: block;
  flex-shrink: 0;
  margin-right: 30px;

  ${({ theme }) => theme.mq.md`
    display: none;
`}
`;

export const Main = styled(Flex)`
  align-items: center;
  width: 100%;
`;

export const SearchInput = styled.input`
  font-size: ${({ theme }) => theme.fontSizes.sm};
  appearance: none;
  border-radius: 6px;
  border: none;
  box-sizing: border-box;
  line-height: 1.2;
  outline: none;
  padding: 10px;
  padding-left: 0;
  width: 100%;

  ::placeholder {
    color: rgba(165, 172, 192, 0.9);
  }

  ${({ theme }) => theme.mq.md`
  padding: 10px;
  `}
`;

export const SearchWrapper = styled.div`
  background: #ffffff;
  border-radius: 6px;
  border: 1px solid rgba(165, 172, 192, 0.3);
  display: flex;
  flex: 1;
  height: 38px;
  margin-left: auto;
  max-width: 190px;
  transition: all 0.2s;

  :focus-within {
    border: 1px solid #1dcf9f;
  }

  .algolia-autocomplete {
    width: 100%;
  }

  .algolia-autocomplete .ds-dropdown-menu {
    min-width: unset;
    width: calc(100vw - 30px);

    @media screen and (max-width: 576px) {
      right: -55px !important;
    }

    ${({ theme }) => theme.mq.sm`
      max-width: 500px;
      min-width: 300px;
      width: 100%;    
    `};
  }

  .algolia-docsearch-suggestion {
    text-decoration: none;
  }

  .algolia-autocomplete .algolia-docsearch-suggestion--category-header {
    color: ${({ theme }) => theme.colors.dark};
  }

  .algolia-autocomplete .algolia-docsearch-suggestion--subcategory-column {
    color: ${({ theme }) => theme.colors.primary};
  }

  .algolia-autocomplete .algolia-docsearch-suggestion--title {
    color: ${({ theme }) => theme.colors.dark};
    font-weight: normal;
  }

  .algolia-autocomplete .algolia-docsearch-suggestion--text {
    color: ${({ theme }) => theme.colors.primary};
  }

  .algolia-autocomplete .algolia-docsearch-suggestion--highlight {
    background: rgba(28, 197, 152, 0.15);
    box-shadow: none;
    color: ${({ theme }) => theme.colors.secondary};
    padding: 0.2em 0.15em;
  }

  .algolia-autocomplete
    .algolia-docsearch-suggestion--category-header
    .algolia-docsearch-suggestion--category-header-lvl0
    .algolia-docsearch-suggestion--highlight,
  .algolia-autocomplete
    .algolia-docsearch-suggestion--category-header
    .algolia-docsearch-suggestion--category-header-lvl1
    .algolia-docsearch-suggestion--highlight {
    box-shadow: inset 0 -2px 0 0 ${({ theme }) => theme.colors.secondary};
  }

  ${({ theme }) => theme.mq.md`
  margin-left: 0;
  margin-right: 20px;
  max-width: 630px;
  padding-left: 15px;
  
  `}
`;

export const IconWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 10px;
`;

export const NavActions = styled(Flex)`
  align-items: center;
  display: none;
  margin-left: auto;

  ${({ theme }) => theme.mq.md`
    display: flex;
  `}

  > * {
    flex-shrink: 0;

    &:not(:last-child) {
      margin-right: 20px;
    }
  }
`;

export const CreateButton = styled(Button)`
  &,
  &:visited {
    color: ${({ theme }) => theme.colors.white};
  }

  align-items: center;
  background-color: ${({ theme }) => theme.colors.secondary};
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-weight: bold;
  height: 36px;
  outline: none;
`;
