import styled from '@emotion/styled';

export const modalStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    bottom: 0,
    left: 0,
    padding: '3rem 1rem',
    position: 'fixed',
    right: 0,
    top: 0,
    zIndex: 6,
  },
  content: {
    background: '#fff',
    borderRadius: '10px',
    left: '50%',
    maxHeight: 'calc(100vh - 6rem)',
    maxWidth: '54rem',
    outline: 'none',
    overflow: 'unset',
    padding: 0,
    position: 'relative',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
  },
};

export const CloseModalButton = styled.button`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 50%;
  border: none;
  cursor: pointer;
  display: inline-flex;
  height: 2rem;
  justify-content: center;
  outline: none;
  position: absolute;
  right: 0;
  top: -2.5rem;
  width: 2rem;

  ${({ theme }) => theme.mq.md`
    height: 4rem;
    right: -5rem;
    top: 0;
    width: 4rem;
    `}
`;

export const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: calc(100vh - 6rem);
  overflow-y: scroll;
  padding: 10px;
  position: relative;
  width: 100%;
`;
