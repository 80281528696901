import { css } from '@emotion/core';

export const inputMixin = ({ theme }) => css`
  -moz-appearance: none;
  -webkit-appearance: none;
  appearence: none;
  background: ${({ theme }) => theme.colors.bg};
  border-radius: 6px;
  border: 1px solid rgba(165, 172, 192, 0.3);
  color: ${theme.colors.dark};
  display: block;
  font-size: 14px;
  font-weight: normal;
  line-height: 24px;
  margin: 0;
  outline: none;
  padding: 18px 16px;
  width: 100%;

  &::placeholder {
    color: rgba(23, 18, 49, 0.3);
  }

  &:disabled {
    background-color: rgba(255, 255, 255, 0.12);
  }
`;
