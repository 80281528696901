import React from 'react';
import SuccessIcon from '@icons/success.svg';
import { BackButton, Button, Subtitle, Title } from '../styled';
import { Flex } from 'rebass';

export const Step4 = ({ goToPrev, restartWalkthrough }) => {
  return (
    <>
      <BackButton onClick={goToPrev} />
      <Title>Payment Successful</Title>

      <Flex alignItems="center" justifyContent="center" my="3rem">
        <SuccessIcon />
      </Flex>

      <Subtitle mb="3rem">Your payment was successful</Subtitle>
      <Button onClick={restartWalkthrough} mt="auto">
        Done
      </Button>
    </>
  );
};
