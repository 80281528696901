import React, { useMemo } from 'react';
import { Form } from '@components/form';
import { useMutation } from 'react-query';
import { sandbox } from '@services/api/sandbox';
import { BackButton, Button, Subtitle, Title } from '../styled';

export const Step3 = ({ goToNext, goToPrev, form, onInputChange, banks, updateForm, updateStepResponse }) => {
  const [validateAccountNumber, { isLoading }] = useMutation(sandbox.validateAccountNumber);

  const onSubmit = async (e) => {
    e.preventDefault();

    try {
      const resp = await validateAccountNumber({
        bankCode: form.bankCode,
        bankAccountNo: form.bankAccountNo,
        countryCode: form.countryCode,
      });

      updateForm('accountName', resp?.data?.accountName);
      updateStepResponse('3', resp);
      goToNext();
    } catch (e) {
      console.log({ e });
    }
  };

  const disabled = useMemo(() => !form.bankCode || !form.bankAccountNo, [form.bankCode, form.bankAccountNo]);

  return (
    <>
      <BackButton onClick={goToPrev} />
      <Title>Enter bank details</Title>

      <Subtitle mb="3rem">Enter the recipient bank details.</Subtitle>

      <Form onSubmit={onSubmit}>
        <Form.Group>
          <Form.Label>Destination bank</Form.Label>
          <Form.Select value={form.bankCode} name="bankCode" onChange={onInputChange}>
            <option value="" disabled>
              Select bank
            </option>

            {banks.map((bank) => (
              <option value={bank.code} key={bank.code}>
                {bank.name}
              </option>
            ))}
          </Form.Select>
        </Form.Group>

        <Form.Group>
          <Form.Label>Account number</Form.Label>
          <Form.Input
            placeholder="8976545678"
            name="bankAccountNo"
            value={form.bankAccountNo}
            onChange={onInputChange}
          />
        </Form.Group>

        <Button type="button" onClick={onSubmit} loading={isLoading} disabled={disabled}>
          Verify Account
        </Button>
      </Form>
    </>
  );
};
