import React from 'react';
import SuccessIcon from '@icons/success.svg';
import { BackButton, Button, Subtitle, Title } from '../styled';
import { Flex } from 'rebass';

export const Step6 = ({ goToPrev, restartWalkthrough }) => {
  return (
    <>
      <BackButton onClick={goToPrev} />
      <Title>Confirm transfer</Title>

      <Flex alignItems="center" justifyContent="center" my="3rem">
        <SuccessIcon />
      </Flex>

      <Subtitle textAlign="center" mb="3rem">
        Transfer confirmed
      </Subtitle>
      <Button onClick={restartWalkthrough} mt="auto">
        Refresh
      </Button>
    </>
  );
};
