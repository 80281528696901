import React from 'react';
import { BackButton, Button, Subtitle, Title } from '../styled';

export const Step3 = ({ goToPrev, completePayment }) => {
  return (
    <>
      <BackButton onClick={goToPrev} />
      <Title>Complete Payment</Title>
      <Subtitle>Click the send request button to load the OPay checkout and complete the transaction</Subtitle>

      <Button onClick={completePayment} mt="auto">
        Complete Payment
      </Button>
    </>
  );
};
