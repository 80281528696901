import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import NotFoundIcon from '@icons/not-found.svg';
import Cloud from '@icons/cloud.svg';
import Clouds from './clouds';

const Wrapper = styled.div`
  padding: 4rem 0;
`;

const Flex = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const PosterWrapper = styled.div`
  position: relative;

  .cloud {
    display: none;
    position: absolute;
    top: 40px;
    width: 55px;
    ${({ theme }) => theme.mq.lg`
    display: block;
    `}

    &__1 {
      left: -100px;
    }

    &__2 {
      right: -70px;
    }
  }
`;

const Poster = styled.svg`
  height: auto;
  margin-bottom: 70px;
  max-width: 100%;
`;

const Caption = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.sm};
  color: #494949;
  font-family: 300;
  margin-bottom: 2rem;
  text-align: center;

  ${({ theme }) => theme.mq.sm`
  font-size: 18px;
  line-height: 30px;
  `}
`;

const Button = styled.button`
  &,
  &:visited {
    color: ${(props) => props.theme.colors.white};
  }

  align-items: center;
  background-color: ${(props) => props.theme.colors.purple};
  border-radius: 100px;
  box-shadow: 0px 4px 10px rgba(33, 15, 96, 0.3);

  display: flex;
  height: 55px;
  justify-content: center;
  line-height: 1;
  padding: 25px;
  text-align: center;
  text-decoration: none;
  transition: all 0.2s;

  &:hover {
    background-color: ${(props) => props.theme.colors.secondary};
    color: ${(props) => props.theme.colors.white};
    text-decoration: none;
  }
`;

const CTASection = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  max-width: 420px;
  width: 100%;
`;

export const NotFound = () => {
  return (
    <Wrapper>
      <Flex>
        <Clouds />
        <PosterWrapper>
          <Cloud className="cloud cloud__1" />
          <Poster as={NotFoundIcon} />
          <Cloud className="cloud cloud__2" />
        </PosterWrapper>
        <CTASection>
          <Caption>Sorry, we can’t find that page! It might be an old link or maybe it has been removed.</Caption>
          <Button as={Link} to="/">
            Return to homepage
          </Button>
        </CTASection>
      </Flex>
    </Wrapper>
  );
};
