export const numbersOnly = (n) => {
  if (n) {
    const num = `${n}`;
    const regex = /\D/gm;
    return num.replace(regex, '');
  }
  return '';
};

export const numberWithCommas = (x) => {
  const num = numbersOnly(x);
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const generateReferenceID = () => `test_${Math.random().toString(36).substr(2, 9)}`;

export const getURLParameter = (name, url) => {
  return (
    decodeURIComponent(
      (new RegExp(`[?|&]${name}=([^&;]+?)(&|#|;|$)`).exec(url) || [null, ''])[1].replace(/\+/g, '%20'),
    ) || null
  );
};

export const openInNewTab = (url) => {
  window.open(url, '_blank');
};
