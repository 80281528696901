import config from '@config';

const baseUrl = `${config.production}/cashier/initialize`;

export const step1Curl = `curl --location --request POST '${baseUrl}' 
--header 'MerchantId: merchant_id' 
--header 'Authorization: Bearer public_key' 
--header 'Content-Type: application/json' 
`;

export const step2Curl = ({ number, amount, time }) => `curl --location --request POST '${baseUrl}' 
--header 'MerchantId: merchant_id' 
--header 'Authorization: Bearer public_key' 
--header 'Content-Type: application/json' 
--data-raw '{
  "reference": reference_id,
  "mchShortName": merchant_name, 
  "productName": merchant_product,
  "productDesc": product_desc,
  "userPhone": "+${number}",
  "userRequestIp": "123.123.123.123",
  "amount": "${amount || '__'}",
  "currency": "NGN",
  "payTypes":[
    "BalancePayment",
    "BonusPayment",
    "OWealth"
  ],
  "payMethods":[
    "account",
    "qrcode",
    "bankCard",
    "bankAccount"
  ],
  "callbackUrl": "https://you.domain.com/callbackUrl",
  "returnUrl": "https://you.domain.com/returnUrl",
  "expireAt": "${time || '__'}"
}
'
`;
