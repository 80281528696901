import React, { useEffect, useState } from 'react';
import { useLocation } from '@reach/router';
import { Flex, Text } from 'rebass';
import styled from '@emotion/styled/macro';
import ThumbUp from '@icons/thumb-up.svg';
import ThumbDown from '@icons/thumb-down.svg';
import Stars from '@icons/stars.svg';
import { CommentModal } from './CommentModal';

const Wrapper = styled(Flex)`
  display: inline-flex;
  position: relative;
`;

const IconWrapper = styled.div`
  align-items: center;
  background-color: #f4f6f7;
  border-radius: 50%;
  display: flex;
  height: 43px;
  justify-content: center;
  transition: all 0.2s;
  width: 43px;

  &.green--selected {
    background-color: rgba(214, 245, 238, 0.54);
  }

  &.green--hover {
    &:hover {
      background-color: rgba(214, 245, 238, 0.54);
    }
  }

  &.red--hover {
    &:hover {
      background-color: rgba(231, 36, 36, 0.1);
    }
  }
`;

const SelectBox = styled(Flex)`
  align-items: center;
  cursor: pointer;
  position: relative;

  ${IconWrapper} {
    margin-right: 20px;
  }

  svg {
    height: auto;
    width: 22px;
  }

  .text {
    font-size: ${({ theme }) => theme.fontSizes.sm};
    color: ${({ theme }) => theme.colors.primary};
    margin-top: 4px;
    user-select: none;
  }

  &:not(:last-child) {
    margin-right: 20px;
  }
`;

export const Rating = () => {
  const [modal, setModal] = useState(false);
  const [type, setType] = useState('');
  const location = useLocation();

  const toggleModal = () => setModal((v) => !v);

  const onClick = (newType) => {
    setType(newType);
  };

  useEffect(() => {
    setType('');
  }, [location.pathname]);

  const renderType = () => {
    switch (type) {
      case 'yes':
        return (
          <Flex alignItems="center">
            <IconWrapper className="green--selected">
              <ThumbUp />
            </IconWrapper>

            <Text variant="smallBody" mx="10px">
              Thank you! We are happy to help
            </Text>

            <Stars />
          </Flex>
        );

      default:
        return (
          <>
            <Text variant="smallBody" mr="20px">
              Was this page helpful?
            </Text>

            <Flex>
              <SelectBox onClick={() => onClick('yes')}>
                <IconWrapper className="green--hover">
                  <ThumbUp />
                </IconWrapper>
                <span className="text">Yes</span>
              </SelectBox>

              <SelectBox onClick={toggleModal}>
                <IconWrapper className="red--hover">
                  <ThumbDown />
                </IconWrapper>
                <span className="text">No</span>
              </SelectBox>
            </Flex>

            {modal && <CommentModal closeModal={() => setModal(false)} isOpen={modal} />}
          </>
        );
    }
  };

  return (
    <Wrapper alignItems="center" flexWrap="wrap">
      {renderType()}
    </Wrapper>
  );
};
