import axios from 'axios';
import config from '@config';

const create = () => {
  const api = axios.create({
    baseURL: config.utilityURL,
  });

  return {
    createComment: (body) => api.post('/docs/comment', body).then(({ data }) => data.data),
  };
};

const utility = create();

export { utility };
