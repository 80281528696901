import React from 'react';
import { Link } from 'gatsby';
import { Box, Text } from 'rebass';
import Logo from '@icons/logo.svg';
import SearchIcon from '@icons/search.svg';
import ProgressBar from 'react-scroll-progress-bar';
import config from '@config';

import {
  IconWrapper,
  LogoWrapper,
  Main,
  NavActions,
  SearchInput,
  SearchWrapper,
  Wrapper,
  CreateButton,
} from './styled';
import { Hamburger } from '../hamburger';

export const openInNewTab = (url) => {
  window.open(url, '_blank');
};

const Searchbar = (props) => {
  return (
    <SearchWrapper>
      <IconWrapper>
        <SearchIcon />
      </IconWrapper>
      <SearchInput aria-label="Algolia search" id="algolia-search" {...props} />
    </SearchWrapper>
  );
};

export const Nav = ({ sidebarOpen, toggleSidebar }) => {
  return (
    <Wrapper as="nav">
      <LogoWrapper>
        <Link to="/" aria-label="Home link">
          <Logo />
        </Link>
      </LogoWrapper>

      <Main>
        <Searchbar placeholder="Search Documentation" />

        <NavActions>
          <Text
            as="a"
            fontWeight="semibold"
            href="mailto:ng-golive@opay-inc.com"
            style={{ textDecoration: 'none' }}
            variant="body"
            fontSize="md"
          >
            Support
          </Text>

          <Text
            as="a"
            fontWeight="semibold"
            href={config.merchantDashboard}
            target="_blank"
            style={{ textDecoration: 'none' }}
            variant="body"
            fontSize="md"
          >
            Login
          </Text>

          <CreateButton as="a" href={config.merchantRegister} target="_blank" rel="noopener noreferrer">
            Create Account
          </CreateButton>
        </NavActions>

        <Box ml="20px" display={['flex', null, 'none']}>
          <Hamburger active={sidebarOpen} onClick={toggleSidebar} />
        </Box>
      </Main>
      <ProgressBar bgcolor="#1DCF9F" />
    </Wrapper>
  );
};
