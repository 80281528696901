import React, { useMemo, useState } from 'react';
import { Form } from '@components/form';
import { useMutation } from 'react-query';
import { sandbox } from '@services/api/sandbox';
import { Text } from 'rebass';
import { generatePrivateKey } from '@utils/generators';
import { BackButton, Button, Subtitle, Title } from '../styled';

export const Step7 = ({ form, goToNext, goToPrev, onInputChange, updateStepResponse }) => {
  const [error, setError] = useState('');
  const [verifyTransferStatus, { isLoading }] = useMutation(sandbox.transferToBankStatus);

  const onSubmit = async (e) => {
    setError('');
    e.preventDefault();

    try {
      const body = {
        orderNo: form.orderNo,
        reference: form.reference,
      };

      const key = generatePrivateKey(body);
      const headers = { Authorization: `Bearer ${key}` };
      const resp = await verifyTransferStatus({ body, headers });

      if (resp.message !== 'SUCCESSFUL') {
        setError(resp.message);
      } else {
        updateStepResponse('7', resp);
        goToNext();
      }
    } catch (e) {
      console.log({ e });
    }
  };

  const disabled = useMemo(() => !form.reference || !form.orderNo, [form.reference, form.orderNo]);

  return (
    <>
      <BackButton onClick={goToPrev} />
      <Title>Confirm transfer</Title>

      <Subtitle mb="3rem">Specify the Reference Number and Order Number returned by the Transfer to bank API.</Subtitle>

      <Form onSubmit={onSubmit}>
        <Form.Group>
          <Form.Label>Reference number</Form.Label>
          <Form.Input placeholder="298764AC667" name="reference" value={form.reference} onChange={onInputChange} />
        </Form.Group>

        <Form.Group>
          <Form.Label>Order number</Form.Label>
          <Form.Input
            placeholder="20019212912901281821982"
            name="orderNo"
            value={form.orderNo}
            onChange={onInputChange}
          />
        </Form.Group>

        {error && (
          <Form.Group>
            <Text variant="error">{error}</Text>
          </Form.Group>
        )}

        <Button type="button" onClick={onSubmit} disabled={disabled} loading={isLoading}>
          Verify Transaction Status
        </Button>
      </Form>
    </>
  );
};
