import React from 'react';
import { Form } from '@components/form';
import { BackButton, Button, Subtitle, Title } from '../styled';

export const Step4 = ({ goToNext, goToPrev, form }) => {
  return (
    <>
      <BackButton onClick={goToPrev} />
      <Title>Enter bank details</Title>

      <Subtitle mb="3rem">Enter the recipient bank details.</Subtitle>

      <Form>
        <Form.Group>
          <Form.Label>Account name</Form.Label>
          <Form.Input placeholder="John Doe Biden" name="accountName" value={form.accountName} />
        </Form.Group>

        <Button type="button" onClick={goToNext}>
          Verify Account Name
        </Button>
      </Form>
    </>
  );
};
