import React from 'react';
import styled from '@emotion/styled';
import NoteIcon from '@icons/note-icon.svg';
import TipIcon from '@icons/tip-icon.svg';
import WarningIcon from '@icons/warning-icon.svg';
import ErrorIcon from '@icons/error-icon-red.svg';
import { Box, Heading, Text } from 'rebass';

const types = {
  note: {
    icon: NoteIcon,
    background: 'rgba(29, 207, 159, 0.1)',
    border: '#1DCF9F',
  },
  tip: {
    icon: TipIcon,
    background: 'rgba(29, 207, 159, 0.1)',
    border: 'rgba(29, 207, 159, 0.5)',
  },
  warning: {
    icon: WarningIcon,
    background: 'rgba(254, 206, 47, 0.16)',
    border: '#FECE2F',
  },
  error: {
    icon: ErrorIcon,
    background: 'rgba(255, 58, 68, 0.16)',
    border: 'rgba(255, 58, 68)',
  },
};

export const Alert = ({ caption, children, title, type, ...props }) => {
  const currentType = types[type];

  return (
    <Container {...props}>
      <Wrapper type={type}>
        <IconAndTitleWrapper>
          <Icon as={currentType.icon} alt={currentType.title} />
          <Heading as="span" ml="1rem" fontSize={['sm', null, 'md']}>
            {title}
          </Heading>
        </IconAndTitleWrapper>
        <Body>{children ? children : <Text variant="smallBody">{caption}</Text>}</Body>
      </Wrapper>
    </Container>
  );
};

Alert.defaultProps = {
  type: 'note',
};

const Container = styled(Box)`
  width: 100%;

  &:not(:last-of-type) {
    margin-bottom: 15px;
  }
`;

const Wrapper = styled.div`
  background: ${({ type }) => types[type].background};
  border-radius: 6px;
  border-left: 4px solid ${({ type }) => types[type].border};
  width: 100%;
`;

const IconAndTitleWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  padding: 30px 0 10px 20px;
`;

const Body = styled.div`
  padding: 0 20px 20px;
`;

const Icon = styled.svg`
  width: 20px;
  height: 20px;
`;
