import React from 'react';
import { Form } from '@components/form';
import { generateReferenceID, numbersOnly } from '@utils';
import { BackButton, Button, Subtitle, Title } from '../styled';
import { sandbox } from '@services/api/sandbox';
import { useMutation } from 'react-query';

export const Step2 = ({ form, goToNext, goToPrev, onSubmitSuccess, setForm }) => {
  const [initializeCashier, { isLoading }] = useMutation(sandbox.initializeCashier);

  const onTextChange = (name, value) => {
    setForm((v) => ({ ...v, [name]: value }));
  };

  const emptyForm = () => {
    return !Object.values(form).every((v) => v);
  };

  const getOriginUrl = () => {
    if (typeof window !== `undefined`) {
      return location.origin;
    }
    return '';
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (!emptyForm()) {
      try {
        const resp = await initializeCashier({
          amount: form.amount,
          callbackUrl: `${getOriginUrl()}/callback-url`,
          currency: 'NGN',
          expireAt: form.time,
          mchShortName: "Jerry's shop",
          payTypes: ['BalancePayment', 'BonusPayment', 'OWealth'],
          payMethods: ['account', 'qrcode', 'bankCard', 'bankAccount'],
          productDesc: 'The best wireless earphone in history',
          productName: 'Apple AirPods Pro',
          reference: generateReferenceID(),
          returnUrl: 'javascript:window.close()',
          userPhone: `+${form.number}`,
          userRequestIp: '124.156.149.61',
        });

        onSubmitSuccess(resp);
        goToNext();
      } catch (e) {}
    }
  };

  return (
    <>
      <BackButton onClick={goToPrev} />
      <Title>Enter the payment details</Title>

      <Subtitle mb="3rem">Helps us run a check if the user is registered on OPay</Subtitle>

      <Form onSubmit={onSubmit}>
        <Form.Group>
          <Form.Label>Customer’s mobile number</Form.Label>
          <Form.Input name="number" disabled value={form.number} />
        </Form.Group>

        <Form.Group>
          <Form.Label>Amount (kobo)</Form.Label>
          <Form.Input
            placeholder="0"
            name="amount"
            value={form.amount}
            onChange={(e) => onTextChange('amount', numbersOnly(e.target.value))}
          />
          <Form.Text>Minimum amount is 10</Form.Text>
        </Form.Group>

        <Form.Group>
          <Form.Label>Time for link to expire (minutes)</Form.Label>
          <Form.Input
            placeholder="0"
            name="time"
            value={form.time}
            onChange={(e) => onTextChange('time', numbersOnly(e.target.value))}
          />
          <Form.Text>After this time, the user will not be able to make payment</Form.Text>
        </Form.Group>

        <Button type="button" disabled={emptyForm()} onClick={onSubmit} loading={isLoading}>
          Send Request
        </Button>
      </Form>
    </>
  );
};
