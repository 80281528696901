import React from 'react';
import { Form } from '@components/form';
import { BackButton, Button, Subtitle, Title } from '../styled';

export const Step2 = ({ goToNext, goToPrev, countries, form, onInputChange }) => {
  return (
    <>
      <BackButton onClick={goToPrev} />
      <Title>Select country</Title>

      <Subtitle mb="3rem">Select the country recipients’ bank account is domiciled in.</Subtitle>

      <Form>
        <Form.Group>
          <Form.Label>Country</Form.Label>
          <Form.Select value={form.countryCode} name="countryCode" onChange={onInputChange}>
            <option value="" disabled>
              Select country
            </option>
            {countries.map((country) => (
              <option value={country.code} key={country.code}>
                {country.name}
              </option>
            ))}
          </Form.Select>
        </Form.Group>

        <Button type="button" onClick={goToNext} disabled={!form.countryCode}>
          Send Request
        </Button>
      </Form>
    </>
  );
};
