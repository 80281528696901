import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';
import { inputMixin } from './mixins';
import 'react-phone-input-2/lib/style.css';
const PhoneInputComponent = loadable(() => import(`react-phone-input-2`), {
  fallback: <div>loading...</div>,
});

export const InputComponent = styled.input`
  ${inputMixin}
  height: 40px;
`;

const PhoneInputWrapper = styled.div`
  .react-tel-input {
    background: ${({ theme }) => theme.colors.bg};
    border: 1px solid rgba(165, 172, 192, 0.3);
    height: 40px;

    .flag-dropdown {
      background: transparent;
      border: none;
      position: relatvie;
      &:after {
        background-color: #e7e9eb;
        content: '';
        height: 70%;
        position: absolute;
        right: -3px;
        top: 15%;
        width: 1px;
      }
    }

    .form-control {
      border: none;
      width: 100%;
      height: 100%;
    }
  }
`;

export const Input = ({ type, onChange, onBlur, onFocus, error, ...props }) => {
  if (type === 'phone') {
    return (
      <PhoneInputWrapper error={error}>
        <PhoneInputComponent autoFormat country="ng" inputProps={{ ...props }} onChange={onChange} {...props} />
      </PhoneInputWrapper>
    );
  }

  return <InputComponent error={error} onChange={onChange} onBlur={onBlur} type={type} onFocus={onFocus} {...props} />;
};

Input.propTypes = {
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  type: PropTypes.string,
};

Input.defaultProps = {
  error: '',
  type: 'text',
};
