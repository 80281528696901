import React, { useEffect, useMemo, useState } from 'react';
import { PrismCode } from '@components';
import { useSteps } from '@hooks';
import { step1Curl, step2Curl, step5Curl } from '@data/walkthrough/walletTransfer';
import { Left, Right, Wrapper } from '../styled';
import { Step1 } from './Step1';
import { Step2 } from './Step2';
import { Step3 } from './Step3';
import { Step4 } from './Step4';
import { Step5 } from './Step5';
import { Step6 } from './Step6';

const INITIAL_FORM = {
  type: '',
  name: 'Test',
  merchantId: '256620072116000',
  phoneNumber: '+2348131393827',
  amount: '',
  reason: '',
  reference: '',
  orderNo: '',
};

export const WalletTransferWalkthrough = () => {
  const [form, setForm] = useState({ ...INITIAL_FORM });
  const [stepResponse, setStepResponse] = useState({});
  const { currentStep, goToNext, goToPrev, goToStep } = useSteps(1, 8);

  useEffect(() => {
    let name;
    if (form.type === 'USER') {
      name = 'Omojo Negedu';
    } else {
      name = 'Test';
    }
    updateForm('name', name);
  }, [form.type]);

  const updateStepResponse = (key, value) => {
    setStepResponse((v) => ({
      ...v,
      [key]: JSON.stringify(value, null, 2),
    }));
  };

  const restartWalkthrough = () => {
    setForm({ ...INITIAL_FORM });
    setStepResponse({});
    goToStep(1);
  };

  const updateForm = (key, value) => {
    setForm((v) => ({ ...v, [key]: value }));
  };

  const onInputChange = (e) => {
    const { name, value } = e.target;
    updateForm(name, value);
  };

  const stepProps = useMemo(
    () => ({
      form,
      goToNext,
      goToPrev,
      onInputChange,
      restartWalkthrough,
      updateForm,
    }),
    [form, goToNext, goToPrev, onInputChange, restartWalkthrough, updateForm],
  );

  const showCurrentStep = () => {
    switch (currentStep) {
      case 1:
        return <Step1 {...stepProps} />;
      case 2:
        return <Step2 {...stepProps} />;
      case 3:
        return <Step3 {...stepProps} updateStepResponse={updateStepResponse} />;
      case 4:
        return <Step4 {...stepProps} />;
      case 5:
        return <Step5 {...stepProps} updateStepResponse={updateStepResponse} />;
      case 6:
        return <Step6 {...stepProps} />;

      default:
        return <Step1 {...stepProps} />;
    }
  };

  const currentCodeSnippet = () => {
    switch (currentStep) {
      case 1:
        return step1Curl;
      case 2:
        return step2Curl(form);
      case 3:
        return step2Curl(form);
      case 4:
        return stepResponse['3'] || '';
      case 5:
        return step5Curl(form);
      case 6:
        return stepResponse['5'] || '';

      default:
        return step1Curl;
    }
  };

  return (
    <Wrapper>
      <Left>{showCurrentStep()}</Left>
      <Right>
        <PrismCode language="javascript" code={currentCodeSnippet()} />
      </Right>
    </Wrapper>
  );
};
