import React from 'react';
import styled from '@emotion/styled';

export const Wrapper = styled.div`
  align-items: center;
  background-color: #ffffff;
  border-radius: 6px 6px 0px 0px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
  display: flex;
  padding: 14px 20px;

  .type {
    font-size: ${({ theme }) => theme.fontSizes.sm};
    background-color: #e9e3fc;
    border-radius: 4px;
    color: #8962fe;
    font-weight: bold;
    padding: 7px 10px;
    text-align: center;
    text-transform: uppercase;
  }

  .url {
    font-size: ${({ theme }) => theme.fontSizes.sm};
    color: ${({ theme }) => theme.colors.primary};
    margin: 0 0 0 15px;
  }

  + .gatsby-highlight {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    margin-top: 0;
    font-size: ${({ theme }) => theme.fontSizes.sm};
  }
`;

export const CodeHeader = ({ type, url }) => {
  return (
    <Wrapper>
      <span className="type">{type}</span>
      <span className="url">{url}</span>
    </Wrapper>
  );
};
