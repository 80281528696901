import Home from '@icons/home.svg';
import Calculator from '@icons/calculator.svg';
import Receipt from '@icons/receipt.svg';
import Transfers from '@icons/transfers.svg';
import Inquiry from '@icons/inquiry.svg';
import Library from '@icons/library.svg';
import OPayWalletPayment from '@icons/pay-with-opay.svg';
import OPayUserCreation from '@icons/opay-user-creation.svg';
import DirectCard from '@icons/direct-card-transaction.svg';
import Dice from '@icons/dice.svg';
import Callback from '@icons/callback.svg';
// import ErrorCodes from '@icons/error-codes.svg';

export const sidebarWidth = {
  md: '230px',
  xl: '370px',
};

export const sidebarLinks = [
  {
    text: 'Introduction',
    path: '/',
    icon: Home,
  },
  {
    text: 'OPay Payment Gateway',
    icon: Receipt,
    path: '/checkout',
    subMenu: [
      {
        text: 'Overview',
        path: '/checkout',
      },
      {
        text: 'H5 Checkout (For Web based payments)',
        path: '/checkout/h5-checkout',
      },
      {
        text: 'Login & Payment SDK',
        path: '/checkout/login-and-payment-sdk',
      },
      {
        text: 'About OPay Merchant Dashboard',
        path: '/checkout/merchant-access',
      },
    ],
  },

  {
    text: 'Callback',
    icon: Callback,
    path: '/callback',
    subMenu: [
      {
        text: 'Callback Signature Calculator',
        path: '/callback/signature-calculator',
      },
    ],
  },

  {
    text: 'Transfers',
    path: '/transfers',
    icon: Transfers,
    subMenu: [
      {
        text: 'Overview',
        path: '/transfers',
      },
      {
        text: 'Transfer to OPay Wallet',
        path: '/transfers/transfer-to-opay-wallet',
      },
      {
        text: 'Batch Transfer to OPay Wallet',
        path: '/transfers/batch-transfer-to-opay-wallet',
      },
      {
        text: 'Transfer to Bank Account',
        path: '/transfers/transfer-to-bank-account',
      },
      {
        text: 'Batch Transfer to Bank Account',
        path: '/transfers/batch-transfer-to-bank-account',
      },
    ],
  },
  {
    text: 'Signature Calculator (HMAC)',
    path: '/signature-calculator',
    icon: Calculator,
  },
  {
    text: 'OPay Inquiry',
    path: '/inquiry',
    icon: Inquiry,
    subMenu: [
      {
        text: 'Overview',
        path: '/inquiry',
      },
      {
        text: 'Query Balance',
        path: '/inquiry/query-balance',
      },
      {
        text: 'Validate OPay User',
        path: '/inquiry/validate-opay-user',
      },
      {
        text: 'Validate OPay Merchant',
        path: '/inquiry/validate-opay-merchant',
      },
      {
        text: 'Validate Bank Account Number',
        path: '/inquiry/validate-bank-account-number',
      },
    ],
  },
  {
    text: 'OPay Wallet Payment',
    path: '/opay-wallet-payment',
    icon: OPayWalletPayment,
  },
  {
    text: 'OPay User Account Creation',
    path: '/opay-user-account-creation',
    icon: OPayUserCreation,
  },
  {
    text: 'Transactions',
    path: '/transaction',
    icon: DirectCard,
    subMenu: [
      {
        text: 'Overview',
        path: '/transaction',
      },
      {
        text: 'Card Payments',
        path: '/transaction/card-payments',
      },
      {
        text: 'Bank Transfers',
        path: '/transaction/bank-transfers',
      },
      {
        text: 'USSD Payments',
        path: '/transaction/ussd-transfers',
      },
    ],
  },
  // {
  //   text: 'Error Codes',
  //   path: '/error-codes',
  //   icon: ErrorCodes,
  // },
  {
    text: 'Betting/Airtime Topup',
    path: '/betting-airtime-topup',
    icon: Dice,
  },
  {
    text: 'Libraries',
    path: '/libraries',
    icon: Library,
  },
];
