import React from 'react';
import { Box } from 'rebass';
import styled from '@emotion/styled';

const TableWrapper = styled(Box)`
  background: ${({ theme }) => theme.colors.white};
  border-radius: 6px;
  border: 1px solid rgba(165, 172, 192, 0.2);
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  display: flex;
  margin-bottom: 3rem;
  overflow-x: auto;
  width: 100%;

  span.data-type {
    color: ${({ theme }) => theme.colors.orange};
    display: block;
  }

  span.link {
    color: ${({ theme }) => theme.colors.secondary};
    display: block;
  }

  span.text {
    color: ${({ theme }) => theme.colors.secondary};
  }

  tr {
    &:nth-child(even) {
      background-color: ${({ theme }) => theme.colors.white};
    }

    &:nth-child(odd) {
      background-color: ${({ theme }) => theme.colors.background};
    }
  }
`;

const Table = (props) => {
  return (
    <TableWrapper shadow="sm" rounded="lg">
      <Box as="table" width="100%" {...props} />
    </TableWrapper>
  );
};

const TableHead = (props) => {
  return <Box as="thead" {...props} />;
};

const TableRow = (props) => {
  return <Box as="tr" {...props} />;
};

const TableHeader = (props) => {
  return (
    <Box
      as="th"
      backgroundColor="white"
      borderBottomWidth="1px"
      borderColor="#eaeaea"
      color="primary"
      fontSize="14px"
      fontWeight="medium"
      lineHeight="24px"
      px="2rem"
      py="3rem"
      textAlign="left"
      {...props}
    />
  );
};

const TableBody = (props) => {
  return <Box as="tbody" {...props} />;
};

const TableCell = (props) => {
  return (
    <Box
      as="td"
      px="2rem"
      py="1.5rem"
      fontSize="14px"
      color="primary"
      lineHeight="24px"
      whiteSpace="nowrap"
      {...props}
    />
  );
};

Table.Body = TableBody;
Table.Head = TableHead;
Table.Td = TableCell;
Table.Th = TableHeader;
Table.Tr = TableRow;

export { Table };
