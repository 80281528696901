import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { inputMixin } from './mixins';
import caretDown from '@images/select-caret-down.png';
import spin from '@images/select-spinner.gif';

export const Select = styled.select`
  ${inputMixin}
  background-image: url(${caretDown});
  background-position: calc(100% - 1rem) 50%;
  background-repeat: no-repeat;
  background-size: 12px;
  height: 40px;
  padding: 5px 16px;

  ${({ loading }) =>
    loading &&
    css`
      background-image: url(${spin});
      background-size: 25px;
      pointer-events: none;
    `}
`;
