import React, { useMemo } from 'react';
import { Form } from '@components/form';
import { useMutation } from 'react-query';
import { sandbox } from '@services/api/sandbox';
import { generateReferenceID, numbersOnly } from '@utils';
import { generatePrivateKey } from '@utils/generators';
import { BackButton, Button, Subtitle, Title } from '../styled';

export const Step5 = ({ form, goToNext, goToPrev, onInputChange, updateForm, updateStepResponse }) => {
  const [transferToBank, { isLoading }] = useMutation(sandbox.transferToBank);

  const onSubmit = async (e) => {
    e.preventDefault();

    try {
      const body = {
        amount: form.amount,
        country: form.countryCode,
        currency: 'NGN',
        reason: form.reason,
        receiver: {
          bankAccountNumber: form.bankAccountNo,
          bankCode: form.bankCode,
          name: form.accountName,
        },
        reference: generateReferenceID(),
      };

      const key = generatePrivateKey(body);
      const headers = { Authorization: `Bearer ${key}` };
      const resp = await transferToBank({ body, headers });
      updateForm('reference', resp?.data?.reference || '');
      updateForm('orderNo', resp?.data?.orderNo || '');
      updateStepResponse('5', resp);
      goToNext();
    } catch (e) {
      console.log({ e });
    }
  };

  const disabled = useMemo(() => !form.amount || !form.reason, [form.amount, form.reason]);

  return (
    <>
      <BackButton onClick={goToPrev} />
      <Title>Transfer fund</Title>

      <Subtitle mb="3rem">Enter the payment details</Subtitle>

      <Form onSubmit={onSubmit}>
        <Form.Group>
          <Form.Label>Amount (kobo)</Form.Label>
          <Form.Input
            placeholder="0"
            name="amount"
            value={form.amount}
            onChange={(e) => updateForm('amount', numbersOnly(e.target.value))}
          />
          <Form.Text>Minimum amount is 100, maximum is 999,999</Form.Text>
        </Form.Group>

        <Form.Group>
          <Form.Label>Transaction remarks</Form.Label>
          <Form.Input placeholder="For fund" name="reason" value={form.reason} onChange={onInputChange} />
        </Form.Group>

        <Button type="button" onClick={onSubmit} loading={isLoading} disabled={disabled}>
          Transfer Funds
        </Button>
      </Form>
    </>
  );
};
